/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "ap-southeast-2",
  "aws_cloud_logic_custom": [
      {
          "name": "AdminQueries",
          "endpoint": "https://3jplmyzi14.execute-api.ap-southeast-2.amazonaws.com/fredon",
          "region": "ap-southeast-2"
      }
  ],
  "aws_appsync_graphqlEndpoint": "https://l75du5jupzgqbgayhdynhpfohi.appsync-api.ap-southeast-2.amazonaws.com/graphql",
  "aws_appsync_region": "ap-southeast-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_cognito_identity_pool_id": "ap-southeast-2:b6107c35-7841-4bb6-9d16-9cced934444a",
  "aws_cognito_region": "ap-southeast-2",
  "aws_user_pools_id": "ap-southeast-2_FJtuhmOLX",
  "aws_user_pools_web_client_id": "40fvacuqj99ftbn5nvuqlmhefg",
  "oauth": {},
  "aws_cognito_username_attributes": [
      "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
      "EMAIL",
      "FAMILY_NAME",
      "GIVEN_NAME",
      "PHONE_NUMBER"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
      "SMS"
  ],
  "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 8,
      "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
      "EMAIL"
  ],
  "aws_user_files_s3_bucket": "intellitrackstorage113204-fredon",
  "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;